import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    topicals
    keywords
    author

  } = @search_filters
  q = {

    topical_id_in: topicals if topicals.length
    name_or_author_i_cont_all: keywords if keywords

  }
  request = get('/blog_entries/search.json', {params: {id: @id, q, @page, sort: [@sort]}})
  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.blog_entries.length
      new_blog_entries = @blog_entries
      new_blog_entries.push(data.blog_entries...)
      @blog_entries = UniqBy(new_blog_entries, 'id')
      if (quantity < 4)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    @blog_entries = []
    request.then ({data}) =>
      @page++
      @blog_entries = data.blog_entries
      @loading = false
)
