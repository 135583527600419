#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
# import VueAutonumeric from 'vue-autonumeric'
import AxiosRequest from "../utils/axios-request"
import UniqBy from 'lodash.uniqby'


# Local imports
import fetchBlogEntries from '../methods/blog_entries/fetch-blog_entries'
import toCurrency from '../filters/to_currency'

Vue.use(TurbolinksAdapter)
document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById 'index-blog_entries'
  return false unless el?
  data = ->
    {searchParams} = new URL(window.location.href) # Url params
    topicals = []
    page = 1
    sort =
      updated_at: 'desc'
      name: false

    # Url params
    topicals_param = parseInt(searchParams.get('category'))


    # Negotiation
    topicals.push(topicals_param) if topicals_param

    page |= parseInt searchParams.get('page')
    keywords = searchParams.get('keyword')
    {
      blog_entries: []
      filter_interactions: 1
      page
      loading: true
      search_filters: {
        topicals

        keywords
      }
      sort
    }
  new Vue {
    el
    data
    mounted: ->
      theTopicals = @search_filters.topicals

    methods: {
      fetchBlogEntries
      filtersChanged: ->
        window.scrollTo(0, 0)
        @page = 1
        @fetchBlogEntries()
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchBlogEntries() if reload
      infiniteHandler: ($state) ->
        @fetchBlogEntries('infinite', $state)
      resetState: ->
        history.pushState({}, 'Ver Todas nuestras entradas', document.location.origin + document.location.pathname)
        Object.assign @$data, data()

      sortControl: (order) ->
        @sort = order
        @filtersChanged()
    }
    watch: {
      'search_filters.topicals':
        handler: (topicals) ->
          @filtersChanged()

        immediate: true

      'search_filters.keywords': -> @filtersChanged()

    }
    components: {
      InfiniteLoading
    }
    computed: {
      active_filters: ->
        f = @search_filters
        f.topicals.length || f.keywords
    }
    filters: {
      toCurrency
    }
  }
